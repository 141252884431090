import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DialogAddReservationComponent } from 'app/dialog/dialog-add-reservation/dialog-add-reservation.component';
import { DialogConfirmReservationComponent } from 'app/dialog/dialog-confirm-reservation/dialog-confirm-reservation.component';
import { DialogEditReservationComponent } from 'app/dialog/dialog-edit-reservation/dialog-edit-reservation.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';
import { Ticket } from 'app/model/entity/ticket';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { Reservation } from 'app/model/entity/ticket-manager/reservation';
import { ReservationDetail } from 'app/model/entity/ticket-manager/reservationDetail';
import { Reservations } from 'app/model/entity/ticket-manager/reservations';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';
import _ from 'lodash';
import moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'tab-reservation-manager',
  templateUrl: './tab-reservation-manager.component.html',
  styleUrls: ['./tab-reservation-manager.component.scss']
})
export class TabReservationManagerComponent implements OnInit {
  @Input() tabSelected: number; // tab selected
  @Input() listApp: ApplicationDTO[]; // list Application
  @Input() informationAccount: any;
  @Input() dataSearchFromReservation: any;
  @Input() dataReservationRegistration: any;
  Constant = Constant;
  languageKey: string;
  languageCode: string;
  config: any;
  reservations: Reservations;
  reservationDisplays: Reservation[];
  reservationSelected: Reservation;
  reservationDetail: ReservationDetail;
  listTicket: Ticket[];
  commonObject: Common;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  currentPageNumber: number;
  lastPageNumber: number;
  totalReservations: number;
  pageSize: number;
  helper = Helper;

  dataSearch: any;

  headerColumns = [
    { headerName: 'ticket-manager.reservation-manager.reservation-id', property: 'reservationId' },
    { headerName: 'ticket-manager.reservation-manager.reservation-code', property: 'reservationVerifyCode' },
    { headerName: 'ticket-manager.reservation-manager.user-id', property: 'userId' },
    { headerName: 'ticket-manager.reservation-manager.reserve-on', property: 'reserveOn' },
    { headerName: 'ticket-manager.reservation-manager.individual-ticket-name', property: 'ticketName' },
    { headerName: 'ticket-manager.reservation-manager.trip-id', property: 'tripId' },
    { headerName: 'ticket-manager.reservation-manager.description', property: 'description' },
    { headerName: 'ticket-manager.reservation-manager.price-class-id', property: 'reservationClassId' },
    { headerName: 'ticket-manager.reservation-manager.representative-name', property: 'representativeName' },
    { headerName: 'ticket-manager.reservation-manager.phone-number', property: 'phoneNumber' },
    { headerName: 'ticket-manager.reservation-manager.email', property: 'email' },
    { headerName: 'ticket-manager.reservation-manager.total-count', property: 'totalCount' },
    { headerName: 'ticket-manager.reservation-manager.reservation-price', property: 'reservationPrice' }
  ];

  columns = [
    'reservationId',
    'reservationVerifyCode',
    'userId',
    'reserveOn',
    'ticketName',
    'tripId',
    'description',
    'reservationClassId',
    'representativeName',
    'phoneNumber',
    'email',
    'totalCount',
    'reservationPrice'
  ];

  headerColumnReservationDetail1: any = [
    { label: 'ticket-manager.reservation-manager.reservation-id', field: 'reservationId' },
    { label: 'ticket-manager.reservation-manager.reservation-code', field: 'reservationVerifyCode' },
    { label: 'ticket-manager.reservation-manager.user-id', field: 'userId' },
    { label: 'ticket-manager.reservation-manager.reserve-on', field: 'reserveOn' },
    { label: 'ticket-manager.reservation-manager.order-ID', field: 'orderId' },
    { label: 'ticket-manager.reservation-manager.ticket-id', field: 'ticketId' },
    { label: 'ticket-manager.reservation-manager.individual-ticket-name', field: 'ticketName' },
    { label: 'ticket-manager.reservation-manager.trip-id', field: 'tripId' },
    { label: 'ticket-manager.reservation-manager.trip-name', field: 'name' },
    { label: 'ticket-manager.reservation-manager.description', field: 'stockPartitionDescription' },
    { label: 'ticket-manager.reservation-manager.total-price', field: 'totalPrice' }
  ];

  headerColumnReservationDetail2: any = [
    { label: 'ticket-manager.reservation-manager.reservation-method', field: 'reservationMethod' },
    { label: 'ticket-manager.reservation-manager.reservation-status', field: 'reservationStatusName' },
    { label: 'ticket-manager.reservation-manager.representative-name', field: 'representativeName' },
    { label: 'ticket-manager.reservation-manager.phone-number', field: 'phoneNumber' },
    { label: 'ticket-manager.reservation-manager.email', field: 'email' },
    { label: 'ticket-manager.reservation-manager.price-class-id', field: 'reservationClassId' },
    { label: 'ticket-manager.reservation-manager.price-class', field: 'reservationClassName' },
    { label: 'ticket-manager.reservation-manager.dynamic-price-type', field: 'dynamicPriceType' },
    { label: 'ticket-manager.reservation-manager.price-description', field: 'reservationPriceDescription' },
    { label: 'ticket-manager.reservation-manager.remark-answer', field: 'remarkAnswer' }
  ];

  constructor(
    private translateService: TranslateService,
    private commonService: CommonService,
    private menuActionService: MenuActionService,
    private ticketEditorService: TicketEditorService,
    private ticketManagerService: TicketManagerService,
    private dialogService: DialogService,
    private dataService: DataService
  ) {
    this.subscriptions.push(
      this.menuActionService.actionAddReservation.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.RESERVATION_MANAGER_ENUM) {
          this.addReservation();
        }
      }),
      this.menuActionService.actionEditReservation.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.RESERVATION_MANAGER_ENUM) {
          this.editReservation();
        }
      }),
      this.menuActionService.actionDeleteReservation.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.RESERVATION_MANAGER_ENUM) {
          this.deleteReservation();
        }
      }),
      // Clear setting
      this.menuActionService.actionClearSetting.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.RESERVATION_MANAGER_ENUM) {
          this.clearSearch();
        }
      }),
      this.translateService.onLangChange.subscribe(() => {
        // multi language date picker
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.languageCode = this.languageKey == 'en' ? 'en' : 'ja';
        this.config = {
          showWeekNumbers: false,
          format: 'YYYY-MM-DD',
          firstDayOfWeek: 'mo',
          unSelectOnClick: false,
          locale: Helper.getLocale(this.languageKey)
        };
      })
    );
    this.commonObject = this.commonService.getCommonObject();
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonObject?.setting?.language;
    this.languageCode = this.languageKey == 'en' ? 'en' : 'ja';
    this.config = {
      showWeekNumbers: false,
      format: 'YYYY-MM-DD',
      firstDayOfWeek: 'mo',
      unSelectOnClick: false,
      locale: Helper.getLocale(this.languageKey)
    };
    this.resetDataSearch();
    await this.getMaximumRecord();
    this.dataSearch.pageLimit = _.cloneDeep(this.pageSize);
    if (this.dataSearchFromReservation) {
      this.dataSearch.startDate = this.dataSearchFromReservation.reserveOn.substring(0, 10);
      this.dataSearch.endDate = this.dataSearchFromReservation.reserveOn.substring(0, 10);
      this.dataSearch.tripId = this.dataSearchFromReservation.tripId;
      this.dataSearch.reservationClassId = this.dataSearchFromReservation.reservationClassId;
      this.dataSearch.appId = this.dataSearchFromReservation.appId;
      await this.changeApp(this.dataSearch.appId);
      this.dataSearch.ticketId = this.dataSearchFromReservation.ticketId;
      this.searchReservation();
    }
    if (this.dataReservationRegistration) {
      this.addReservation(this.dataReservationRegistration);
    }
  }

  ngOnDestroy(): void {
    this.reservations = undefined;
    this.reservationDisplays = undefined;
    this.reservationSelected = undefined;
    this.reservationDetail = undefined;
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * getMaximumRecord
   */
  async getMaximumRecord(): Promise<void> {
    return new Promise<void>(resolve => {
      this.ticketManagerService.getMaximumRecord().subscribe(
        data => {
          this.pageSize = data ?? Constant.DEFAULT_MAXIMUM_RECORD;
          resolve();
        },
        error => {
          resolve();
        }
      );
    });
  }

  /**
   * searchReservation
   * @param pageNumber
   * @param reservationId
   */
  searchReservation(pageNumber?: number, reservationId?: string) {
    this.reservations = null;
    this.lastPageNumber = null;
    this.totalReservations = null;
    this.reservationDisplays = [];
    this.reservationSelected = null;
    this.reservationDetail = null;
    this.dataSearch.pageNumber = pageNumber ?? '';
    this.ticketManagerService.getReservations(this.dataSearch).subscribe(
      data => {
        if (!data || (data && data.reservations.length == 0)) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('ticket-manager.reservation-manager.no-data')
            }
          });
          return;
        }
        this.reservations = data;
        this.currentPageNumber = _.cloneDeep(this.reservations.currentPageNumber);
        this.lastPageNumber = _.cloneDeep(this.reservations.lastPageNumber);
        this.totalReservations = _.cloneDeep(this.reservations.totalReservations);
        this.reservationDisplays = _.cloneDeep(this.reservations.reservations);
        if (!this.reservationDisplays || !this.reservationDisplays.length) {
          this.dataService.sendData([Constant.HAS_RESERVATION_SELECTED, false]);
          return;
        }
        if (reservationId) {
          const index = this.reservationDisplays.findIndex(e => e.reservationId == reservationId);
          index != -1
            ? this.selectReservation(this.reservationDisplays[index])
            : this.selectReservation(this.reservationDisplays[Constant.FIRST_ELEMENT_INDEX]);
        } else {
          this.selectReservation(this.reservationDisplays[Constant.FIRST_ELEMENT_INDEX]);
        }
      },
      error => {
        this.dataService.sendData([Constant.HAS_RESERVATION_SELECTED, false]);
      }
    );
  }

  /**
   * selectReservation
   * @param reservation
   * @param event
   */
  selectReservation(reservation: any) {
    if (!reservation) {
      this.dataService.sendData([Constant.HAS_RESERVATION_SELECTED, false]);
      return;
    }
    this.reservationSelected = reservation;
    const payload = {
      reservationId: this.reservationSelected.reservationId
    };
    this.ticketManagerService.getReservation(payload).subscribe(
      data => {
        this.reservationDetail = data;
        if (!this.reservationDisplays || !this.reservationDisplays.length || !this.reservationDetail) {
          this.dataService.sendData([Constant.HAS_RESERVATION_SELECTED, false]);
          return;
        } else {
          this.dataService.sendData([Constant.HAS_RESERVATION_SELECTED, true]);
        }
      },
      error => {
        this.reservationDetail = null;
        this.dataService.sendData([Constant.HAS_RESERVATION_SELECTED, false]);
      }
    );
  }

  /**
   * getTitle
   * @returns
   */
  public getTitle(): string {
    if (!this.dataSearch.appId) {
      return;
    }
    return this.listApp?.find(app => app.appId == this.dataSearch.appId)?.appName;
  }

  /**
   * open date picker
   * @param picker
   * @param time
   */
  openDatePicker(picker: DatePickerDirective, time: any): void {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  /**
   * add element date picker
   */
  addPseudoSpan(): void {
    while (document.getElementById('span-new')) {
      document.getElementById('span-new').remove();
    }
    return;
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: String): String {
    if (!value) {
      return;
    }
    let temp = _.cloneDeep(value).toString();
    if (temp?.split('W')?.length > 10 && temp.length > 21) {
      value = value.substring(0, 21) + '...';
    } else if (value.length > 36) {
      value = value.substring(0, 36) + '...';
    }
    return value;
  }

  /**
   * addReservation
   * @param dataReservationRegistration
   */
  addReservation(dataReservationRegistration?: any) {
    this.dialogService.showDialog(
      DialogAddReservationComponent,
      {
        data: {
          languageKey: this.languageKey,
          informationAccount: this.informationAccount,
          reservationDetail: dataReservationRegistration
        }
      },
      result => {
        if (result) {
          this.resetDataSearch();
          this.dataSearch.reservationId = result;
          this.searchReservation(null, this.dataSearch.reservationId);
        }
      }
    );
  }

  /**
   * editReservation
   */
  editReservation() {
    this.dialogService.showDialog(
      DialogEditReservationComponent,
      {
        data: {
          languageKey: this.languageKey,
          reservationDetail: this.reservationDetail
        }
      },
      result => {
        if (result) {
          this.resetDataSearch();
          this.dataSearch.reservationId = result;
          this.searchReservation(null, this.dataSearch.reservationId);
        }
      }
    );
  }

  /**
   * deleteReservation
   */
  deleteReservation() {
    const priceLabelCustom1 = !this.helper.isEmpty(this.reservationDetail.priceCustom1)
      ? !this.reservationDetail.userId
        ? this.reservationDetail?.priceLabelCustom1
        : this.reservationDetail.custom1Name[this.languageCode]
      : undefined;
    const priceLabelCustom2 = !this.helper.isEmpty(this.reservationDetail.priceCustom2)
      ? !this.reservationDetail.userId
        ? this.reservationDetail?.priceLabelCustom2
        : this.reservationDetail.custom2Name[this.languageCode]
      : undefined;
    const priceLabelCustom3 = !this.helper.isEmpty(this.reservationDetail.priceCustom3)
      ? !this.reservationDetail.userId
        ? this.reservationDetail?.priceLabelCustom3
        : this.reservationDetail.custom3Name[this.languageCode]
      : undefined;
    const reservationDetail = {
      reservationId: this.reservationDetail.reservationId,
      representativeName: this.reservationDetail.representativeName,
      phoneNumber: this.reservationDetail.phoneNumber,
      email: this.reservationDetail.email,
      remarkAnswer: this.reservationDetail.remarkAnswer,
      ticketName: this.reservationDetail.ticketName,
      reserveOn: this.reservationDetail.reserveOn,
      name: this.reservationDetail.name,
      reservationClassId: this.reservationDetail.reservationClassId,
      reservationPriceDescription: this.reservationDetail.reservationPriceDescription,
      reservationNumberAdult: !this.helper.isEmpty(this.reservationDetail.priceAdult)
        ? this.reservationDetail.reservationNumberAdult
        : undefined,
      priceLabelCustom1: priceLabelCustom1,
      reservationNumberCustom1: !this.helper.isEmpty(this.reservationDetail.priceCustom1)
        ? this.reservationDetail.reservationNumberCustom1
        : undefined,
      priceLabelCustom2: priceLabelCustom2,
      reservationNumberCustom2: !this.helper.isEmpty(this.reservationDetail.priceCustom2)
        ? this.reservationDetail.reservationNumberCustom2
        : undefined,
      priceLabelCustom3: priceLabelCustom3,
      reservationNumberCustom3: !this.helper.isEmpty(this.reservationDetail.priceCustom3)
        ? this.reservationDetail.reservationNumberCustom3
        : undefined,
      totalPrice: this.reservationDetail?.totalPrice,
      reservationStatus: this.reservationDetail.reservationStatus
    };
    this.dialogService.showDialog(DialogConfirmReservationComponent, {
      data: {
        reservationDetail: reservationDetail,
        languageKey: this.languageKey,
        isDelete: true
      }
    });
  }

  /**
   * changeApp
   * @param appId
   */
  async changeApp(appId: string): Promise<void> {
    this.listTicket = [];
    this.dataSearch['ticketId'] = '';

    if (!appId) {
      return;
    }

    try {
      const data = await this.ticketEditorService.getTickets(this.informationAccount, appId, null, true).toPromise();
      if (!data) {
        return;
      }
      this.listTicket = data;
    } catch (error) {}
  }

  /**
   * displayScheduleAt
   * @param time
   * @returns
   */
  displayTime(time: string) {
    if (!time) {
      return '';
    }
    return time.substring(0, 16).replace('T', ' ');
  }

  /**
   * selectPage
   * @param event
   */
  selectPage(event: any) {
    if (!event) {
      return;
    }
    this.currentPageNumber = event;
    this.searchReservation(this.currentPageNumber);
  }

  /**
   * getReserationMethod
   * @param input
   * @returns
   */
  getReserationMethod(input: string) {
    if (!input) {
      return this.translateService.instant('ticket-manager.reservation-manager.contact-telephone');
    }
    return this.translateService.instant('ticket-manager.reservation-manager.app-booking');
  }

  /**
   * getTotalCount
   * @param adult
   * @param custom1
   * @param custom2
   * @param custom3
   * @returns
   */
  getTotalCount(adult: any, custom1: any, custom2: any, custom3: any) {
    let totalCount = 0;
    if (!this.helper.isEmpty(adult)) {
      totalCount += adult;
    }
    if (!this.helper.isEmpty(custom1)) {
      totalCount += custom1;
    }
    if (!this.helper.isEmpty(custom2)) {
      totalCount += custom2;
    }
    if (!this.helper.isEmpty(custom3)) {
      totalCount += custom3;
    }
    return totalCount;
  }

  /**
   * convertSpace
   */
  convertSpace(): void {
    if (!this.dataSearch.representativeName) {
      return;
    }
    this.dataSearch.representativeName = this.dataSearch.representativeName.replace(/　/g, ' ');
  }
  /**
   * resetDataSearch
   */
  resetDataSearch(): void {
    this.dataSearch = {
      reservationId: '',
      representativeName: '',
      phoneNumber: '',
      email: '',
      userId: '',
      reservationVerifyCode: '',
      reservationStatus: '',
      orderUsageStatus: '',
      appId: '',
      orderId: '',
      ticketId: '',
      operatorId: '',
      tripId: '',
      startDate: '',
      endDate: '',
      pageNumber: null,
      pageLimit: _.cloneDeep(this.pageSize),
      sortBy: '',
      reservationClassId: ''
    };
  }

  /**
   * clearSearch
   */
  clearSearch(): void {
    this.resetDataSearch();
    this.reservations = null;
    this.lastPageNumber = null;
    this.totalReservations = null;
    this.reservationDisplays = [];
    this.reservationSelected = null;
    this.reservationDetail = null;
  }
}
